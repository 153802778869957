import API from '@/services/config/config.js'

export default {
    getResidents ({page, count, home, q, orderBy, order}) {
        const param = {page, count, home, q, orderBy, order}
        if(param['home']){
            return API.get('/residents/?page='+page + '&count='+count + '&home='+home)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/residents/', {params:param})
            .then(response => {
                return response.data
            })
        }
        
    },
    deleteResident (id) {
        return API.delete('/residents/' + id)
            .then(response => {
                return response.data
            })
    },
    createResident (resident) {
        return API.post('/residents', resident)
    },
    refreshResident () {
        return API.get('/residents/refresh')
            .then(response => {
                return response.data
            })
    },
    patchResident (id) {
        return API.patch('/residents/', + id)
            .then(response => {
                return response.data
            })
    },
    getResident (id) {
        return API.get('/residents/' + id)
            .then(response => {
                return response.data
            })
    },
}