import { AbilityBuilder, Ability } from '@casl/ability';
export default function defineAbility() {
  const role = localStorage.getItem('role')
  const { can, build } = new AbilityBuilder(Ability);
      switch(role) {
      case 'Admin':
      case 'Director':
      case 'Supervisor':
      case 'CCSO':
      case 'CEO':
      case 'Administrator':
        can(['add', 'read','update', 'delete']);
        break;
      case 'Manager':
        can(['add', 'read','update', 'delete']);
        break;
      case 'Senior':
        can(['add', 'read']);
        break;
      case 'Clerk':
        can('read');
        break;  
    }

  return build()
  }