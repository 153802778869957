import API from '@/services/config/config.js'

export default {
    getRegisteredMedication ({page, count, id, home, q, orderBy, order}) {
        const param = {page, count, id,home, q, orderBy, order}
        if(param['id']){
            return API.get('/medicationAdministrations/?page=' +page + '&count='+count + '&resident='+id)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/medicationAdministrations/', {params:param})
            .then(response => {
                return response.data
            })
        }
        
    },
    deleteRegisteredMedication (id) {
        return API.delete('/medicationAdministrations/' + id)
            .then(response => {
                return response.data    
            })
    },
    registerMedication (medicationAdministrations) {
        return API.post('/medicationAdministrations', medicationAdministrations)
    },
    patchMedication (id, medicationAdministrations) {
        return API.patch('/medicationAdministrations/' + id, medicationAdministrations)
            .then(response => {
                return response.data
            })
    },
}