export default{
    state: {
        leftDrawer: false
      },
      mutations: {
        setDrawer (state, data) {
          state.leftDrawer = data
        }
      },
      getters : {
        leftDrawer: (state) => state.leftDrawer
      }
}