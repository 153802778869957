import remedyApi from '../../services/api/HomelyRemediesApi';

export default {
    state : {
        homelyRemedies: []
    },
    mutations: {
        set_homely_remedy (state, homelyRemedies) {
            state.homelyRemedies = homelyRemedies['result']
        },
        add_homely_remedy  (state, remedy) {
            state.homelyRemedies.push(remedy)
        },
        update_homely_remedy (state, remedy) {
            const remedyId = remedy.id
            state.homelyRemedies.splice(state.homelyRemedies.findIndex(remedy => remedy.id === remedyId), 1, remedy)
        },
        remove_homely_remedy(state, remedy) {
            const remedyId = remedy.id
            state.homelyRemedies.splice(state.homelyRemedies.findIndex(remedy => remedy.id === remedyId), 1)
        },
    },
    getters: {
        allhomelyRemedies (state) {
            return state.homelyRemedies
        },
    },
    actions: {
        get_registered_homely_remedy_list (context) {
            return remedyApi.getRegisteredHomelyRemedy() 
                .then(homelyRemedies => {
                    context.commit('set_homely_remedy', homelyRemedies)
                    return homelyRemedies
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        register_homely_remedy (context, remedy) {
            return remedyApi.registerHomelyRemedy(remedy)
                .then(remedy => {
                    context.commit('add_homely_remedy', remedy)
                    return remedy
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_homely_remedy (context, remedy) {
            const id = remedy.id
            delete remedy.id
            return remedyApi.patchHomelyRemedy(id, remedy)
                .then(remedy => {
                    return remedy
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        delete_homely_remedy (context, remedy) {
            return remedyApi.deleteRegisteredHomelyRemedy(remedy.id)
                .then(remedy => {
                    context.commit('remove_homely_remedy', remedy)
                    return remedy
                })
        },
    },

}