import medicationApi from '../../services/api/MedicationApi';

export default {
    state : {
        medications: []
    },
    mutations: {
        set_medication (state, medications) {
            state.medications = medications['result']
        },
        add_medication  (state, medication) {
            state.medications.push(medication)
        },
        update_medication (state, medication) {
            const medicationId = medication.id
            state.medications.splice(state.medications.findIndex(medication => medication.id === medicationId), 1, medication)
        },
        remove_medication(state, medication) {
            const medicationId = medication.id
            state.medications.splice(state.medications.findIndex(medication => medication.id === medicationId), 1)
        },
    },
    getters: {
        allMedications (state) {
            return state.medications
        },
    },
    actions: {
        get_registered_medication_list (context) {
            return medicationApi.getRegisteredMedication() 
                .then(medications => {
                    context.commit('set_medication', medications)
                    return medications
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        register_medication (context, medication) {
            return medicationApi.registerMedication(medication)
                .then(medication => {
                    context.commit('add_medication', medication)
                    return medication
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_medication (context, medication) {
            const id = medication.id
            delete medication.id
            return medicationApi.patchMedication(id, medication)
                .then(medication => {
                    return medication
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        delete_medication (context, medication) {
            return medicationApi.deleteRegisteredMedication(medication.id)
                .then(medication => {
                    context.commit('remove_medication', medication)
                    return medication
                })
        },
    },

}