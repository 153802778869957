import API from '@/services/config/config.js'

export default {
    getCurrentUser ({page, count, q}) {
        const param = {page, count, q} 
        return API.get('/users/', {params:param})
            .then(response => {
                return response.data
            })
    },
    getLoggedInUser () {
        return API.get('/users/me/')
            .then(response => {
                return response.data
            })
    },
}