import API from '@/services/config/config.js'

export default {
    getRegisteredRemedy ({page, count, id, q, orderBy, order}) {
        const param = {page, count, id, q, orderBy, order}
        if(param['id']){
            return API.get('/remedies/?page=' +page + '&count='+count + '&resident='+id)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/remedies/', {params:param})
            .then(response => {
                return response.data
            })
        }
    },
    deleteRegisteredRemedy (id) {
        return API.delete('/remedies/' + id)
            .then(response => {
                return response.data    
            })
    },
    registerRemedy (remedyRegister) {
        return API.post('/remedies', remedyRegister)
    },
    patchRemedy (id, remedy) {
        return API.patch('/remedies/' + id, remedy)
            .then(response => {
                return response.data
            })
    },
}