<template>
  <v-app id="app" light>
      
      <v-app-bar app clipped-left light color="#53a3a3" class="elevation-2" v-if="isLoggedIn">
          <v-app-bar-nav-icon v-on:click="leftDrawer = !leftDrawer"></v-app-bar-nav-icon>
          <v-toolbar-title class="white--text">Johnson Medication</v-toolbar-title>
          <v-spacer />
          <v-btn class="sm-4" v-on:click="logout" depressed small>Logout</v-btn>
      </v-app-bar>
      <Navbar :leftDrawer="leftDrawer" v-if="isLoggedIn"></Navbar>
      <v-content>
          <v-container fluid>
              <router-view />
          </v-container>
      </v-content>
  </v-app>
</template>

<script>
import Navbar from './components/base/Navigation'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  components: {
    Navbar,
  },
  data() {
    return {
      drawer: null
    }
  },
   computed: {
     ...mapGetters([
       'isLoggedIn'
     ]),
    leftDrawer: {
      get () { return this.$store.getters.leftDrawer },
      set (v) { return this.$store.commit('setDrawer', v) }
    }
  },
  methods: {
        logout: function () {
            this.$store.dispatch('authLogout')
            .then( () => {
                this.$router.push('/auth/login')
            })
        }
    },
}
</script>

<style lang='scss'>
</style>
