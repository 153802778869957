import remedyApi from '../../services/api/RemedyApi';

export default {
    state : {
        remedies: []
    },
    mutations: {
        set_remedy (state, remedies) {
            state.remedies = remedies['result']
        },
        add_remedy  (state, remedy) {
            state.remedies.push(remedy)
        },
        update_remedy (state, remedy) {
            const remedyId = remedy.id
            state.remedies.splice(state.remedies.findIndex(remedy => remedy.id === remedyId), 1, remedy)
        },
        remove_remedy(state, remedy) {
            const remedyId = remedy.id
            state.remedies.splice(state.remedies.findIndex(remedy => remedy.id === remedyId), 1)
        },
    },
    getters: {
        allremedies (state) {
            return state.remedies
        },
    },
    actions: {
        get_registered_remedy_list (context) {
            return remedyApi.getRegisteredRemedy() 
                .then(remedies => {
                    context.commit('set_remedy', remedies)
                    return remedies
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        register_remedy (context, remedy) {
            return remedyApi.registerRemedy(remedy)
                .then(remedy => {
                    context.commit('add_remedy', remedy)
                    return remedy
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_remedy (context, remedy) {
            const id = remedy.id
            delete remedy.id
            return remedyApi.patchRemedy(id, remedy)
                .then(remedy => {
                    return remedy
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        delete_remedy (context, remedy) {
            return remedyApi.deleteRegisteredRemedy(remedy.id)
                .then(remedy => {
                    context.commit('remove_remedy', remedy)
                    return remedy
                })
        },
    },

}