import userApi from '@/services/api/UserApi'

export default {
    state: {
        user_me: {}
    },
    mutations: {
        set_user (state, user_me) {
            state.user_me = user_me
        }
    },
    getters: {
        getLoggedUser (state) {
            return state.user_me
        }
    },
    actions: {
        getLoggedInUser (context) {
            return userApi.getLoggedInUser() 
                .then(user_me => {
                    context.commit('set_user', user_me)
                    return user_me
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
    }
}