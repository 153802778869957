import HomeApi from '@/services/api/Homes'

export default {
    state : {
        homes: []
    },
    mutations: {
        set_homes (state, homes) {
            state.homes = homes
        },
        add_home  (state, home) {
            state.homes.push(home)
        },
        update_home (state, home) {
            const homeId = home.homeId
            state.homes.splice(state.homes.findIndex(home => home.id === homeId), 1, home)
        },
        remove_home(state, home) {
            const homeId = home.id
            state.homes.splice(state.homes.findIndex(home => home.id === homeId), 1)
        },
    },
    getters: {
        allHomes (state) {
            return state.homes
        },
    },
    actions: {
        get_home_list (context) {
            return HomeApi.getHomes() 
                .then(homes => {
                    context.commit('set_homes', homes)
                    return homes
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        create_home (context, home) {
            return HomeApi.createHome(home)
                .then(home => {
                    context.commit('add_home', home)
                    return home
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_home (context, home) {
            return HomeApi.patchHome(home)
                .then(home => {
                    context.commit('update_home', home)
                    return home
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        refresh_home_list (context) {
            return HomeApi.refreshHome() 
                .then(homes => {
                    context.commit('set_homes', homes)
                    return homes
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
    },

}