<template>
    <v-navigation-drawer v-model="leftDrawer" clipped fixed app class="drawer-style ma-0 pa-0">
      <v-list>
        <v-list-item-group  active-class="border">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            router :to="item.action"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list> 
    </v-navigation-drawer>
  </template>

<script>
export default {
    data: () => ({
      item: 1,
      items: [
          { text: 'Dashboard', icon: 'dashboard', action: "users/me"},
          { text: 'Homes', icon: 'home', action: "/homes" },
          { text: 'Residents', icon: 'person', action: "/residents" },
          { text: 'Medications', icon: 'person', action: "/medications" },
          { text: 'Medications Administration', icon: 'person', action: "/medicationsAdministration" },
          { text: 'Control Drug', icon: 'person', action: "/controlDrugRegister" },
          { text: 'Control Drug Administration', icon: 'person', action: "/controlDrugAdmin" },
          { text: 'Remedies', icon: 'person', action: "/remedies" },
          { text: 'Homely Remedies', icon: 'person', action: "/homelyRemedies" },
      ],
    }),
    computed: {
        leftDrawer: {
          get () { return this.$store.getters.leftDrawer },
          set (v) { return this.$store.commit('setDrawer', v) }
        }
        
    },
}
</script>

<style lang='scss'>
  .drawer-style {
    background-color: #559246 !important;
    border-color: #34eb77 !important;
  }
</style>