import API from '@/services/config/config.js'

export default {
    getRegisteredMedication ({page, count, id, q, orderBy, order}) {
        const param = {page, count, id, q, orderBy, order}
        if(param['id']){
            return API.get('/medicationRegisters/?page=' +page + '&count='+count + '&resident='+id, '&q='+q)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/medicationRegisters/', {params:param})
            .then(response => {
                return response.data
            })
        }
    },
    getAllMedication ({page, count, id, q, orderBy, order}) {
        const param = {page, count, id, q, orderBy, order}
        if(param['id']){
            return API.get('/medicationRegisters/?page=' +page + '&count='+count + '&resident='+id, '&q='+q)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/medicationRegisters/', {params:param})
            .then(response => {
                return response.data
            })
        }
    },
    deleteRegisteredMedication (id) {
        return API.delete('/medicationRegisters/' + id)
            .then(response => {
                return response.data    
            })
    },
    registerMedication (medicationRegister) {
        return API.post('/medicationRegisters', medicationRegister)
    },
    patchMedication (id, medication) {
        return API.patch('/medicationRegisters/' + id, medication)
            .then(response => {
                return response.data
            })
    },
}