import API from '@/services/config/config.js'

export default {
    getRegisteredHomelyRemedy ({page, count, home, q, orderBy, order}) {
        const param = {page, count, home, q, orderBy, order}
        if(param['home']){
            return API.get('/homelyRemedies/?page=' +page + '&count='+count + '&home='+home)
                .then(response => {
                    return response.data
                })
        }
        else{
            return API.get('/homelyRemedies/', {params:param})
            .then(response => {
                return response.data
            })
        }
        },
    deleteRegisteredHomelyRemedy (id) {
        return API.delete('/homelyRemedies/' + id)
            .then(response => {
                return response.data    
            })
    },
    registerHomelyRemedy (remedyRegister) {
        return API.post('/homelyRemedies', remedyRegister)
    },
    patchHomelyRemedy (id, remedy) {
        return API.patch('/homelyRemedies/' + id, remedy)
            .then(response => {
                return response.data
            })
    },
}