import API from '@/services/config/config.js'

export default {
    getHomes (page, count, q) {
        const param = {page, count, q}
        return API.get('/homes/', {params:param})
            .then(response => {
                return response.data
            })
    },
    deleteHome (id) {
        return API.get('/homes/' + id)
            .then(response => {
                return response.data
            })
    },
    createHome (home) {
        return API.post('/homes', home)
    },
    refreshHome () {
        return API.get('/homes/refresh')
            .then(response => {
                return response.data
            })
    },
    patchHome (id) {
        return API.patch('/homes/', + id)
            .then(response => {
                return response.data
            })
    },
}