import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'

Vue.use(Router)

let router = new Router ({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/homes'
        },
        {
            path: '/auth',
            component: AuthLayout,
            children: [
                {
                    name: 'login',
                    path: 'login',
                    component: () => import('../components/auth/login/Login.vue'),
                    meta: { 
                        guest: true
                    }, 
                },
                {
                    name: 'logout',
                    path: 'logout',
                    component: () => import('../components/auth/logout/Logout.vue'),
                    meta: { 
                        requiresAuth: true
                    },
                },
            ],
        },
        {
            path: '/users/me',
            meta: { requiresAuth: true},
            component: () => import('../components/users/Dashboard.vue'),
            children: [
                {
                    name:'user',
                    path: '',
                    component: () => import('../components/users/ComponentsList.vue'),
                },
            ]

        },
        {
            path: '/homes',
            component: () => import('../components/homes/Home.vue'),
            meta: { 
                requiresAuth: true
            },
            children: [
                {
                    name:'homes',
                    path: '',
                    component: () => import('../components/homes/HomesList.vue'),
                },
            ]
        },
        {
            path: '/residents',
            component: () => import('../components/residents/Resident.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'residentListHome',
                    path: '',
                    component: () => import('../components/residents/ResidentList.vue'),
                },
                {
                    name:'residentList',
                    path: '/residents/:homeCode?',
                    component: () => import('../components/residents/ResidentList.vue'),
                },
            ]
        },
        {
            path: '/medications',
            component: () => import('../components/medication/Medication.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name: 'medicationList',
                    path: '',
                    component: () => import('../components/medication/MedicationHomeBasedList.vue'),
                },
                {
                    name:'medications',
                    path: 'medications/:residentId',
                    component: () => import('../components/medication/MedicationList.vue'),
                    props: true,
                },
                {
                    name: 'registerMedication',
                    path: 'register/:residentId',
                    component: () => import('../components/medication/RegisterMedication.vue'),
                },
                {
                    name: 'medicationDetail',
                    path: 'details/:medicationId/:residentId',
                    component: () => import('../components/medication/MedicationDetail.vue'),
                },
            ]
        },
        {
            path: '/medicationsAdministration',
            component: () => import('../components/medicationAdministration/MedicationAdministration.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'medicationsAdministration',
                    path: '',
                    component: () => import('../components/medicationAdministration/MedicationAdminList.vue'),
                },
                {
                    name: 'registerMedicationAdministration',
                    path: 'register/:residentId',
                    component: () => import('../components/medicationAdministration/RegisterMedicAdmin.vue'),
                },
                {
                    name: 'medicationAdministrationDetail',
                    path: 'details/:medicationId/:totalMedications',
                    component: () => import('../components/medicationAdministration/MedicationAdministrationDetail.vue'),
                },
            ]
        },
        {
            path: '/controlDrugRegister',
            component: () => import('../components/controlDrugRegister/ControlDrug.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'controlDrugList',
                    // path: 'controlDrugList/:residentId',
                    path:'',
                    component: () => import('../components/controlDrugRegister/ControlDrugRegList.vue'),
                    props:true
                },
                {
                    name: 'createControlDrugRegister',
                    path: 'register/:residentId',
                    component: () => import('../components/controlDrugRegister/CreateControlDrug.vue'),
                },
                {
                    name: 'controlDrugDetail',
                    path: 'details/:residentId/:controlId',
                    component: () => import('../components/controlDrugRegister/ControlDrugDetail.vue'),
                },
            ]
        },
        {
            path: '/controlDrugAdmin',
            component: () => import('../components/controlDrugAdmin/ControlDrugAdmin.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'controlDrugAdminList',
                    // path: 'controlDrugAdminList/:residentId',
                    path:'',
                    component: () => import('../components/controlDrugAdmin/ControlDrugAdminList.vue'),
                    props: true,
                },
                {
                    name: 'createControlDrugAdmin',
                    path: 'register/:residentId',
                    component: () => import('../components/controlDrugAdmin/CreateControlDrugAdmin.vue'),
                },
                {
                    name: 'controlDrugAdminDetail',
                    path: 'details/:residentId/:controlDrugId',
                    component: () => import('../components/controlDrugAdmin/ControlDrugAdminDetail.vue'),
                },
            ]
        },
        {
            path: '/remedies',
            component: () => import('../components/remedies/Remedies.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'remediesList',
                    // path: 'remedies/:residentId',
                    path:'',
                    component: () => import('../components/remedies/RemediesList.vue'),
                    props: true,
                },
                {
                    name: 'registerRemedy',
                    path: 'register/:residentId',
                    component: () => import('../components/remedies/RegisterRemedy.vue'),
                },
                {
                    name: 'remedyDetail',
                    path: 'details/:residentId/:remediesId',
                    component: () => import('../components/remedies/RemedyDetail.vue'),
                },
            ]
        },
        {
            path: '/homelyRemedies',
            component: () => import('../components/homelyRemedies/HomelyRemedies.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name:'homelyRemedies',
                    path: '',
                    component: () => import('../components/homelyRemedies/HomelyRemediesList.vue'),
                },
                {
                    name: 'registerHomelyRemedy',
                    path: 'register',
                    component: () => import('../components/homelyRemedies/RegisterHomelyRemedies.vue'),
                },
                {
                    name: 'remedyHomelyDetail',
                    path: 'details/:homeId/:remediesId',
                    component: () => import('../components/homelyRemedies/HomelyRemediesDetail.vue'),
                },
            ]
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/auth/login',
            })
        }
        else {
            next()
        }
    }
    else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem('token') == null) {
            next()
        }
        else {
            next({
                path: '/',
            })
        }
    }
});

export default router