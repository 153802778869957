import residentApi from '@/services/api/ResidentApi'

export default {
    state : {
        residents: []
    },
    mutations: {
        set_residents (state, residents) {
            state.residents = residents['result']
        },
        add_resident  (state, resident) {
            state.residents.push(resident)
        },
        update_resident (state, resident) {
            const residentId = resident.residentId
            state.residents.splice(state.residents.findIndex(resident => resident.id === residentId), 1, resident)
        },
        remove_resident(state, resident) {
            const residentId = resident.id
            state.residents.splice(state.residents.findIndex(resident => resident.id === residentId), 1)
        },
    },
    getters: {
        allResidents (state) {
            return state.residents
        },
        getResidentsById: (state) => (id) => {
            return state.residents.find(resident => resident.id === id)
          }  
    },
    actions: {
        get_resident_list (context) {
            return residentApi.getResidents() 
                .then(residents => {
                    context.commit('set_residents', residents)
                    return residents
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        create_resident (context, resident) {
            return residentApi.createResident(resident)
                .then(resident => {
                    context.commit('add_resident', resident)
                    return resident
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_resident (context, resident) {
            return residentApi.patchResident(resident)
                .then(resident => {
                    context.commit('update_resident', resident)
                    return resident
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        refresh_resident_list (context) {
            return residentApi.refreshResident() 
                .then(residents => {
                    context.commit('set_residents', residents)
                    return residents
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
    },

}