import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import router from './router/index'
import axios from 'axios'
// import {store} from './store/store'
import store from  './store/store'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import DatetimePicker from 'vuetify-datetime-picker'
import VuePaginate from 'vue-paginate'
import VueHtmlToPaper from 'vue-html-to-paper';
import { abilitiesPlugin } from '@casl/vue';
import defineAbility from './config/ability';
// import { Can } from '@casl/vue';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    '/home/bishwa/G/vuejs/johnson-medication-frontend/src/style.css'
  ]
}

Vue.use(VueHtmlToPaper, options);
// Vue.use(VueHtmlToPaper);
Vue.use(VuePaginate)
window.axios=axios

Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(DatetimePicker)
Vue.use(abilitiesPlugin, defineAbility())
// Vue.component('Can', Can);
export default new Vuetify({ })

new Vue({
  router: router,
  store: store,
  // defineAbility,
  vuetify: new Vuetify(),
  render: h => h(App),
}).$mount('#app')