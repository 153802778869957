import axios from 'axios';
import router from '../../router/index';
import store from '../../store/store'


const instance = axios.create({
  baseURL: 'https://api.medication.johnsoncaregroup.com'
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token ;
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.data.status === 401) {
    store.dispatch('authLogout');
    router.push({ name: 'login'});
  }
  return Promise.reject(error)
})

export default instance;