import axios from 'axios'
import store from '../store'
export default {
    state: {
        status: '',
        token: localStorage.getItem('token') || null,
        role: localStorage.getItem('role') || null,
        homeCode: localStorage.getItem('homeCode') || null,
    },

    // getters
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },

    // mutations
    mutations: {
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token) {
            state.status = 'success'
            state.token = token
        },
        auth_success_user(state, user){
            state.user = user
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            state.token = ''
        },
    },

    // actions
    actions: {
        login(context, credentials){

            return new Promise((resolve, reject) => {
                axios.post('https://api.medication.johnsoncaregroup.com/users/login', {
                    username: credentials.username,
                    password: credentials.password,
                }).then(response => {
                    const token = response.data.token
                    localStorage.setItem('token', token)
                    axios.defaults.headers.common['Authorization'] = token
                    var user = response.data.user
                    context.commit('auth_success', token)
                    context.commit('auth_success_user', user);
                    localStorage.setItem('role', user.role);
                    localStorage.setItem('homeCode', user.home.code);
                    localStorage.setItem('homeId', user.homeId);
                     /* eslint-disable */
                    resolve(response)
                }).catch(error => {
                    context.commit('auth_error')
                    localStorage.removeItem('role')
                    localStorage.removeItem('token')
                    localStorage.removeItem('homeCode')
                    localStorage.removeItem('homeId')
                    reject(error);
                })
            })
        },
        authLogout(context, credentials){
            return new Promise((resolve, reject) => {
                store.commit('setDrawer', true)
                context.commit('logout')
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization'] 
                resolve()
            })
        }
    },
}