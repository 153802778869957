import API from '@/services/config/config.js'

export default {
    getRegisteredControlDrug ({page, count, id, q, orderBy, order}) {
        const param ={page, count, id, q, orderBy, order}
        if(param['id']){
            return API.get('/controlDrugRegisters/?page=' +page + '&count='+count + '&resident='+id)
            .then(response => {
                return response.data
            })
        }
        else{
            return API.get('/controlDrugRegisters/', {params:param})
            .then(response => {
                return response.data
            })
        }
       
    },
    deleteRegisteredControlDrug (id) {
        return API.delete('/controlDrugRegisters/' + id)
            .then(response => {
                return response.data    
            })
    },
    registerControlDrug (controlDrug) {
        return API.post('/controlDrugRegisters', controlDrug)
    },
    patchControlDrug (id, controlDrug) {
        return API.patch('/controlDrugRegisters/' + id, controlDrug)
            .then(response => {
                return response.data
            })
    },
}