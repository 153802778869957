import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import resident from './modules/resident'
import home from './modules/home'
import base from './modules/base'
import medication from './modules/medication'
import user from './modules/user'
import medicationAdministration from './modules/medicationAdministration'
import controlDrugRegister from './modules/controlDrugRegister'
import controlDrugRegisterAdmin from './modules/controlDrugAdmin'
import remedy from './modules/remedy'
import homelyRemedies from './modules/homelyRemedies'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        resident,
        home,
        base,
        medication,
        user,
        medicationAdministration,
        controlDrugRegister,
        controlDrugRegisterAdmin,
        remedy,
        homelyRemedies,
    },
});

export default store