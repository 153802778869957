import controlDrugAdminApi from '../../services/api/ControlDrugAdminApi';

export default {
    state : {
        controlDrug: []
    },
    mutations: {
        set_control_drug_admin (state, controlDrug) {
            state.controlDrug = controlDrug['result']
        },
        add_control_drug_admin  (state, controlDrug) {
            state.controlDrug.push(controlDrug)
        },
        update_control_drug_admin (state, controlDrug) {
            const controlDrugId = controlDrug.id
            state.controlDrug.splice(state.controlDrug.findIndex(drug => drug.id === controlDrugId), 1, controlDrug)
        },
        remove_control_drug_admin(state, controlDrug) {
            const controlDrugId = controlDrug.id
            state.controlDrug.splice(state.controlDrug.findIndex(drug => drug.id === controlDrugId), 1)
        },
    },
    getters: {
        allControlDrugAdmin (state) {
            return state.controlDrug
        },
    },
    actions: {
        get_registered_control_drug_list_admin (context) {
            return controlDrugAdminApi.getRegisteredControlDrug() 
                .then(controlDrug => {
                    context.commit('set_control_drug', controlDrug)
                    return controlDrug
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        register_control_drug_admin (context, controlDrug) {
            return controlDrugAdminApi.registerControlDrug(controlDrug)
                .then(controlDrug => {
                    context.commit('add_control_drug', controlDrug)
                    return controlDrug
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        update_control_drug_admin (context, controlDrug) {
            const id = controlDrug.id
            delete controlDrug.id
            return controlDrugAdminApi.patchControlDrug(id, controlDrug)
                .then(controlDrug => {
                    return controlDrug
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },
        delete_control_drug_admin (context, controlDrug) {
            return controlDrugAdminApi.deleteRegisteredControlDrug(controlDrug.id)
                .then(controlDrug => {
                    context.commit('remove_control_drug', controlDrug)
                    return controlDrug
                })
        },
    },

}